@import './variables';
@import './mixins';
@import "~bootstrap/dist/css/bootstrap-grid.min.css";
// @import "~material-icons/iconfont/material-icons.css";
// @import "~angular-calendar/css/angular-calendar.css";
// @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

html {
  position: relative;
  height: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  margin: 0;
  font-size: 14px;
  background-color: #fff;

}


.input-full-width {
  width: 100%;
}

h1 {
  font-size: 1.5em;
}

h4 {
  font-size: 1em;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  line-height: 1.6em;
}

h3 {
  font-size: 18px;
}

a {
  text-decoration: none;
  color: $celeste_principal;
}

p {
  font-family: 'Roboto', sans-serif;
  line-height: 1.6em;

  // Responsivo
  @include smartphone {
    font-size: 14px;
  }
}

input,
select,
textarea {
  font-weight: normal;
}

ul {
  list-style-type: none;
  margin-top: 4px;
}

textarea {
  max-height: 120px;
  margin: 0 auto;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


button {
  height: 40px;
  outline: none !important;
}

//breadcrumbs
.breadcrumbs {
  display: flex;
  align-items: center;

  .material-icons {
    color: $gray-text;
  }
}

//utilidades
.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.floar-r {
  float: right;
}

//informacion box

.info-box {
  padding: 20px 40px;
  border-radius: 5px;
  color: $celeste_principal;
  background: $celeste_bg-opacity;

  &.reverse {
    background: $gray-bg;
    color: $gray-text;

    h2 {
      font-size: 20px;
      margin-bottom: 20px;
      color: #242216;
    }

    .material-icons {
      color: $celeste_principal;
    }
  }

}

//menu
mat-toolbar {
  background: $white !important;

  .nav-link {
    border-radius: 0;

    &.active {
      box-shadow: inset 0 -3px 0 $celeste_principal;
    }
  }
}

//contenido body

.top-content {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  background: $gray-light;
}

//tablas

.table-default {
  width: 100%;
  border-collapse: collapse;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  overflow: hidden;

  &.gray {

    thead,
    tr {
      background: $gray-bg !important;

      th {
        font-weight: 500;
        font-size: 0.875rem;
        color: $Marine;
      }

      td {
        color: $Marine;
      }
    }
  }

  thead {
    background: $celeste_principal !important;

    th {
      font-weight: 500;
      font-size: 0.875rem;
      color: $white;
    }
  }

  tr,
  td {
    font-weight: 400;
    font-size: 0.875rem;
  }

  .img-user-table {
    height: 30px;
    width: 30px;
    border-radius: 50px;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .e-completo {
    color: $celeste_principal;
  }

  .e-incompleto {
    color: $gray-text;
  }

  .principal-color {
    color: $celeste_principal;
  }

  tr.mat-row,
  tr.mat-footer-row {
    height: 68px;
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}