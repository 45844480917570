$celeste_principal: #18C8BE;
$celeste_bg-opacity: #95FDF7;

$white: #fff;
$white: #fff;
$Marine:#0E0F2D;
$gray-light: #F3F3F4;
$gray-bg:#F8F8F8;
$gray-text:#707070;
$gray:#6E6D7A;
