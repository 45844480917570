$smartphone: 320px;
$tablet: 768px;
$desktop: 1024px;
$full: 1440px;
@mixin smartphone{
  @media (min-width: #{$smartphone}) and (max-width: 767px) {
    @content;
  }
}
@mixin tablet{
  @media (min-width: $tablet) and (max-width: 1024px) {
    @content;
  }
}
@mixin full{
  @media (min-width: $full) and (max-width: 1920px) {
    @content;
  }
}

// /*// Large devices (desktops, 992px and up)*/
// @media (min-width: 992px) and (max-width: 1199px) {
//
// }
//
// /*// Extra large devices (large desktops, 1200px and up)*/
// @media (min-width: 1200px) {
//
//  }
